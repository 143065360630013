import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type DialogContentProps = PropsWithChildren<{} & ClassNameProp>;

const DialogContent = ({ className, ...props }: DialogContentProps) => (
  <div className={twMerge('grow overflow-y-auto', className)} {...props} />
);

export default DialogContent;
