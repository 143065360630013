import { useEffect } from 'react';
import { useLoadStatus } from '../LoadStatus';

type HeroImageSource = {
  key: string;
  width: number;
  url: string;

  /**
   * The URL pointing to the low resolution version of this image that users will
   * see before the actual image loads
   *
   * If not provided, Imgix's `blur` and `q` params will be added to the `url` to
   * request a blurred version of the source image
   *
   * @see https://docs.imgix.com/en-US/apis/rendering/stylize/gaussian-blur
   * @see https://docs.imgix.com/en-US/apis/rendering/format/output-quality
   */
  placeholderUrl?: string;

  /**
   * A media query for the `<source>` element that specifies when it should be
   * displayed
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/source#media
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture
   */
  mediaQuery?: string;
};

type HeroImageProps = {
  alt: string;
  sources: HeroImageSource[];

  /**
   * The image's aspect ratio. It's important that each image in `sources` have
   * the same aspect ratio, since it will be used alongside the image's width to
   * occupy the amount of space that the image will be rendered at once it has
   * loaded.
   *
   * To determine the aspect ratio, you can divide the width by height. When you
   * have an Imgix URL that you're resizing with the `w` parameter, you can go to
   * that image in your browser to see the height that it will resize to.
   *
   * @see https://web.dev/articles/optimize-cls#images-without-dimensions
   */
  aspectRatio: string;
};

const getPlaceholderUrl = (url: string) => {
  const placeholderUrl = new URL(url);

  placeholderUrl.searchParams.set('q', '25');
  placeholderUrl.searchParams.set('blur', '100');

  return placeholderUrl.toString();
};

/**
 * A component that can be used for pictures that are in the critical rendering
 * path, that helps to prevent layout shifting by using a placeholder image and
 * swapping it with the actual, high resolution image once it's loaded
 */
export const HeroImage = ({ alt, aspectRatio, sources }: HeroImageProps) => {
  const { hasLoaded, setHasLoaded } = useLoadStatus();

  /**
   * Preload the images from the source set in the background
   */
  useEffect(() => {
    for (let source of sources) {
      if (!hasLoaded(source.url)) {
        const highResImage = new Image();

        highResImage.src = source.url;

        highResImage.addEventListener('load', () => setHasLoaded(source.url));
      }
    }
  }, []);

  return (
    <picture className="inline h-auto w-auto" style={{ aspectRatio }}>
      {sources.map((source) => (
        <source
          key={source.key}
          media={source.mediaQuery}
          width={source.width}
          srcSet={hasLoaded(source.url) ? source.url : source.placeholderUrl ?? getPlaceholderUrl(source.url)}
        />
      ))}

      <img className="block h-full w-full object-cover" alt={alt} />
    </picture>
  );
};
