import Link from 'next/link';
import { ReactNode } from 'react';

type Layout2x1ImageRightProps = {
  image: ReactNode;
};

const Layout2x1ImageRight = ({ image }: Layout2x1ImageRightProps) => {
  return (
    <div className="row gap-x-0">
      <div className="order-2 col-span-12 flex flex-col justify-center bg-gold-lighter px-24 py-32 md:order-1 md:col-span-5 xl:px-56">
        <h1 className="font-condensed text-base uppercase leading-5">Wedding Suit and Tuxedo Rental</h1>
        <h2 className="md:leading-56 my-16 font-display text-[31.6px] text-black sm:text-[40px] sm:leading-[48px] md:text-[47px]">
          The perfect suit or tuxedo. <br className="hidden xs:inline" /> Delivered.
        </h2>
        <p className="leading-24 text-base">
          Rent for the occasion or make it yours to keep. Explore premium suits and tuxedos delivered to your door.
        </p>

        <div className="my-16 flex justify-between">
          <Link href="/collection/[category]" as="/collection/tuxedos-and-suits">
            <a className="btn-info btn mt-16 w-[48%]">RENT YOUR LOOK</a>
          </Link>
          <Link href="https://buy.generationtux.com/collections/all">
            <a className="btn-info btn mt-16 w-[48%]">BUY YOUR LOOK</a>
          </Link>
        </div>
      </div>

      <div className="portrait:max-h-none md:max-h-none order-1 col-span-12 max-h-[48vh] bg-gold-lighter p-0 md:order-2 md:col-span-7">
        {image}
      </div>
    </div>
  );
};

export default Layout2x1ImageRight;
