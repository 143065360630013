import React, { ReactNode } from 'react';
import FaqGroup from 'components/FaqGroup';
import Layout1x1 from 'components/homepage/Layout1x1';

interface LayoutFaqProps {
  title?: string;
  cta?: ReactNode;
  faqItems?: JSX.Element;
  faqSingle?: ReactNode;
  full?: boolean;
  prominent?: boolean;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & LayoutFaqProps>;

const LayoutFaq: Props = ({
  title = 'Frequently Asked Questions',
  cta,
  faqItems,
  faqSingle,
  className,
  children,
  full = false,
  prominent = false,
  ...props
}) => {
  const Questions = (
    <>
      <div className="space-y-16">
        {faqSingle ? (
          faqSingle
        ) : (
          <FaqGroup title={title} prominent={prominent}>
            {faqItems}
          </FaqGroup>
        )}

        {cta && <div className={`${prominent ? '!mt-32' : ''} flex justify-end`}>{cta}</div>}
      </div>
    </>
  );

  return (
    <>
      {full ? (
        <Layout1x1 {...props} className={` ${className ? className : ''}`}>
          {Questions}
        </Layout1x1>
      ) : (
        <div {...props} className={`container  ${className ? className : ''}`}>
          <div className="row">
            {children && (
              <div
                className="
                  col-start-1 col-end-9 
                  mb-32 space-y-16 
                  xs:col-start-2 xs:col-end-7 
                  sm:col-start-1 sm:col-end-5 
                  sm:mb-0 
                  md:col-start-2 md:col-end-6
                  lg:col-end-5
                "
              >
                {children}
              </div>
            )}

            <div
              className="
                col-start-3 col-end-13 
                xs:col-start-4 xs:col-end-13
                sm:col-start-5 sm:col-end-13
                md:col-start-6 
                lg:col-start-6 lg:col-end-12
              "
            >
              {Questions}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LayoutFaq;
