import type { FC, PropsWithChildren, ReactNode } from 'react';

import IconArrowRight from 'components/IconArrowRight';

type CtaStruct = {
  href: string;
  text: string | ReactNode;
};

export type ResponsiveContentsWithTitleItem = {
  content: string | ReactNode;
  cta?: string | CtaStruct;
  ctaComponent?: FC;
  href?: string;
  picture?: ReactNode;
  title?: string;
};

type ResponsiveContentsWithTitleProps = {
  contents?: ReactNode[]; // 2-4 works best
  items?: ResponsiveContentsWithTitleItem[]; // 2-4 works best
  style?: 'contained' | 'full';
  title?: ReactNode;
  variant?: 'small' | 'large';
} & ClassNameProp &
  DataTestIdProp;

const ResponsiveContentBlocksWithTitle = ({
  className,
  contents,
  items,
  style = 'contained',
  title,
  variant = 'small',
  ...props
}: ResponsiveContentsWithTitleProps) => {
  if (!contents && !items) {
    return null;
  }

  const count = contents ? contents.length : items!.length;

  if (![2, 3, 4].includes(count)) {
    console.warn('ResponsiveContentBlocksWithTitle expects 2-4 items for optimal display.');
  }

  const clsVariantMap = {
    small: {
      content: '',
      contentWrapper: 'text-sm',
      contentWrapperWithPicture: 'ml-16',
      picture: 'w-64 flex-none',
      title: 'block font-bold text-black',
      wrapper: `col-span-12 md:col-span-${12 / count} flex-start flex flex-row`,
    },
    large: {
      content: 'block py-8 text-sm md:text-base',
      contentWrapper: '',
      contentWrapperWithPicture: 'flex flex-col justify-between h-full pt-16',
      picture: '',
      title: 'block text-h4 md:text-h3',
      wrapper: `col-span-6 md:col-span-${12 / count} flex flex-col my-8`,
    },
  };

  const clsx = clsVariantMap[variant];

  return (
    <div data-testid={props['data-testid'] || 'rcwt'} className={className}>
      <div className={`${style === 'contained' && 'container'}`}>
        {title}

        <div className="grid auto-cols-fr grid-cols-12 gap-16">
          {contents
            ? contents.map((jsx, i) => (
                <div className={`col-span-12 md:col-span-${12 / count}`} key={`rcwt-block-${i}`}>
                  {jsx}
                </div>
              ))
            : items?.map(({ content, cta, ctaComponent: CtaComponent, href, picture, title }, i) => {
                const ItemWrapper = (props: PropsWithChildren<ClassNameProp>) =>
                  !(cta || CtaComponent) && href ? <a href={href} {...props} /> : <div {...props} />;
                const PictureWrapper = (props: PropsWithChildren<ClassNameProp>) =>
                  (cta || CtaComponent) && href ? <a href={href} {...props} /> : <div {...props} />;

                return (
                  <ItemWrapper className={clsx.wrapper} key={`${title}-${i}`}>
                    {picture && <PictureWrapper className={clsx.picture}>{picture}</PictureWrapper>}

                    <span className={`${clsx.contentWrapper} ${picture && clsx.contentWrapperWithPicture}`}>
                      <span>
                        {title && <span className={clsx.title}>{title}</span>}
                        <span className={clsx.content}>{content}</span>
                      </span>
                      {CtaComponent ? (
                        <CtaComponent />
                      ) : (
                        typeof cta === 'object' && (
                          <a
                            className="mt-auto flex flex-initial flex-row items-center text-sm font-bold text-gold-darker md:text-base"
                            href={cta.href}
                          >
                            {cta.text} <IconArrowRight className="ml-8 text-gold-darker" />
                          </a>
                        )
                      )}
                    </span>
                  </ItemWrapper>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveContentBlocksWithTitle;
