import type { PropsWithChildren } from 'react';
import type { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import CarouselPagination from './Pagination';

export type CarouselProps = PropsWithChildren<{
  className?: string;
  showPagination?: boolean;
  options?: EmblaOptionsType;
}>;

const Carousel = (props: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'center',
      ...(props.options ?? {}),
    },
    [WheelGesturesPlugin()]
  );

  const showPagination = props.showPagination ?? false;

  return (
    <>
      <div className="overflow-hidden" ref={emblaRef}>
        <div className={props.className}>{props.children}</div>
      </div>

      {showPagination && emblaApi && <CarouselPagination carousel={emblaApi} />}
    </>
  );
};

export default Carousel;
