import { useState, useCallback, useEffect } from 'react';
import type { EmblaCarouselType } from 'embla-carousel';

type CarouselPaginationProps = {
  carousel: EmblaCarouselType;
};

const CarouselPagination = ({ carousel }: CarouselPaginationProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onClick = useCallback(
    (index: number) => {
      carousel.scrollTo(index);
    },
    [carousel]
  );

  const onInit = useCallback((carousel: EmblaCarouselType) => {
    setScrollSnaps(carousel.scrollSnapList());
  }, []);

  const onSelect = useCallback((carousel: EmblaCarouselType) => {
    setSelectedIndex(carousel.selectedScrollSnap());
  }, []);

  useEffect(() => {
    onInit(carousel);
    onSelect(carousel);

    carousel.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
  }, [carousel, onInit, onSelect]);

  return (
    <div className="my-32 flex justify-center space-x-8">
      {scrollSnaps.map((_, index) => (
        <button
          key={index}
          onClick={() => onClick(index)}
          aria-label={`Scroll the carousel to page ${index + 1}`}
          className={`h-4 w-48 cursor-pointer rounded-3xl border-1 transition-[border-color] duration-200 ease-in-out ${
            selectedIndex === index
              ? 'border-gray-dark bg-gray-dark'
              : 'border-gray-light bg-gray-light hover:border-gray'
          }`}
        />
      ))}
    </div>
  );
};

export default CarouselPagination;
