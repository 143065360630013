import Carousel from 'components/emblaCarousel';
import Link from 'next/link';

export type TrendingBundle = {
  title: string;
  cost: string;
  image: string;
  urlSlug: string;
};

const whatsTrendingSliderOptions = {
  dragFree: true,
  slidesToScroll: 2,
  breakpoints: {
    [`(min-width: 538px)`]: {
      slidesToScroll: 3,
    },
  },
} as const;

type TrendingBundleSliderProps = {
  bundles: TrendingBundle[];
};

export const TrendingBundleSlider = (props: TrendingBundleSliderProps) => {
  return (
    <Carousel className="grid grid-flow-col gap-x-16" options={whatsTrendingSliderOptions} showPagination>
      {props.bundles.map((product, index) => (
        <Link href={`/collection/tuxedos-and-suits/${product.urlSlug}`} key={`slide-${index}`}>
          <a className="flex flex-col space-y-8">
            <img
              src={`${product.image}?auto=format&fit=crop&ar=5:7`}
              className="min-w-[150px] max-w-[150px] object-cover aspect-ratio-5by7 sm:max-w-[200px] md:max-w-[300px] xl:max-w-[325px]"
              alt={`A man wearing our ${product.title}`}
            />

            <div className="flex h-full flex-col justify-between space-y-8">
              <p className="text-h5 text-gray-darker sm:text-h4">{product.title}</p>
              <p className="text-gray-darker">
                <span className="text-gray-dark">Rent</span> ${product.cost}
              </p>
            </div>
          </a>
        </Link>
      ))}
    </Carousel>
  );
};
