import type { PropsWithChildren } from 'react';
import Head from 'next/head';

import ClearCookiesBanner from 'components/sanity/ClearCookiesBanner';
import { SanityPageProps } from 'components/sanity/withSanityPageProps';
import { urlFor } from '@root/lib/sanity';
import { SanityImageAsset } from '@root/sanity/sanity.types';
import { SanityReference } from '@root/src/types';

type MaybeAnAsset = SanityImageAsset | SanityReference<SanityImageAsset> | undefined | null;

const getImageUrl = (asset: MaybeAnAsset): string | null => {
  if (asset) {
    return urlFor(asset).url();
  }
  return null;
};

/**
 * Represents a page that is backed by a `page` type document in Sanity Studio
 *
 * This component will make use of the document's meta fields for things such as
 * the standard SEO tags, OpenGraph data, etc.
 *
 * The actual page content is passed to the component as children nodes. At some
 * point in the future, we may want to make it possible for either `children` or
 * a `render` prop to be passed.
 */
export const SanityPage = ({ data, preview, children }: PropsWithChildren<SanityPageProps>) => {
  const { page } = data;

  const ogTitle = page?.opengraphPage?.title ?? '';
  const ogDescription = page?.opengraphPage?.description ?? '';

  return (
    <>
      <Head>
        <title>{page.meta?.title ?? page?.pageName}</title>
        <meta name="description" key="description" content={page?.meta?.description ?? ''} />

        {page?.robotsTxtInclusion && <meta name="robots" key="robots" content="noindex, nofollow" />}

        {page?.opengraphPage && (
          <>
            <meta
              property="og:url"
              key="og:url"
              content={`${process.env.NEXT_PUBLIC_LOCAL_URL}/${page.slug?.current}`}
            />
            <meta property="og:type" key="og:type" content="website" />
            <meta property="og:title" key="og:title" content={ogTitle.trim()} />
            <meta property="og:description" key="og:description" content={ogDescription.trim()} />
            <meta property="og:image" key="og:image" content={getImageUrl(page.opengraphPage?.image?.asset) ?? ''} />

            <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
            <meta name="twitter:domain" key="twitter:domain" content="generationtux.com" />
            <meta
              name="twitter:url"
              key="twitter:url"
              content={`${process.env.NEXT_PUBLIC_LOCAL_URL}/${page.slug?.current}`}
            />
            <meta name="twitter:title" key="twitter:title" content={ogTitle.trim()} />
            <meta name="twitter:description" key="twitter:description" content={ogDescription.trim()} />
            <meta
              name="twitter:image"
              key="twitter:image"
              content={getImageUrl(page.opengraphPage?.image?.asset) ?? ''}
            />
          </>
        )}
      </Head>

      {preview && <ClearCookiesBanner />}

      {children}
    </>
  );
};

export default SanityPage;
