import Link from 'next/link';
import YotpoStarRatings from 'components/YotpoStarRatings';
import Carousel from 'components/emblaCarousel';
import IconArrowRight from 'components/IconArrowRight';

export type Testimonial = {
  content: string;
  customerName: string;
};

type TestimonialsProps = {
  items: Testimonial[];
};

const testimonialSliderOptions = {
  slidesToScroll: 1,
} as const;

export const Testimonials = (props: TestimonialsProps) => {
  return (
    <div className="bg-gold-lighter">
      <div className="container p-32">
        <div className="my-32 flex flex-col items-center justify-between gap-y-16">
          <h2 className="text-center font-condensed text-[20px] uppercase leading-relaxed tracking-wide text-gray-darker">
            See What Other Customers Are Saying
          </h2>

          <YotpoStarRatings alignment="center" showCountOnNewLine />
        </div>

        <div className="row hidden md:grid">
          {props.items.map((testimonial, index) => (
            <div
              key={`desktop-testimonial-${index}`}
              className="col-span-4 flex h-full flex-col justify-between gap-y-16 text-gray-darker"
            >
              <p className="font-display text-[23px] leading-relaxed lg:text-[26px]">
                &#x201C;{testimonial.content}&#x201D;
              </p>

              <p>&mdash; {testimonial.customerName}</p>
            </div>
          ))}
        </div>

        <div className="block md:hidden">
          <Carousel className="flex" options={testimonialSliderOptions} showPagination>
            {props.items.map((testimonial, index) => (
              <div
                key={`mobile-testimonial-${index}`}
                className="col-span-4 flex h-full flex-col justify-between gap-y-16 text-gray-darker"
                style={{ flex: '0 0 100%' }}
              >
                <p className="font-display text-[23px] leading-relaxed lg:text-[26px]">
                  &#x201C;{testimonial.content}&#x201D;
                </p>

                <p>&mdash; {testimonial.customerName}</p>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="mt-64 mb-32 flex flex-col items-center">
          <Link href="/reviews">
            <a className="flex items-center gap-x-8 font-condensed text-[16px] uppercase leading-relaxed tracking-wide text-gold-darker">
              More Reviews <IconArrowRight className="text-gold-darker" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
