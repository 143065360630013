import type { ReactNode } from 'react';
import tw from 'tailwind/tailwind-theme-gentux';

type ProductButtonProps = {
  btnText: string;
  href: string;
  trackerClass?: string;
};

type PanelWithFloatBtnProps = {
  imgUrl: string;
  imgAlt?: string;
  btnText: string;
  href: string;
  trackerClass?: string;
};

type BuySuitOrTuxSectionProps = {
  title: string;
  subtitle: string;
  primaryCta: string | ReactNode;
  secondaryCta: string | ReactNode;
  leftBtn: ProductButtonProps;
  rightBtn: ProductButtonProps;
} & ClassNameProp;

const PanelWithFloatBtn = ({ imgUrl, imgAlt, btnText, href, trackerClass }: PanelWithFloatBtnProps) => (
  <a
    href={href}
    className={`margin-auto relative flex h-auto w-5/6 flex-col xs:w-[300px] sm:items-start  ${trackerClass ?? ''}`}
  >
    <img loading="lazy" src={imgUrl} alt={imgAlt} />
    <button className="btn-info btn absolute bottom-32 left-0 right-0 mx-16">{btnText}</button>
  </a>
);

const BuySuitOrTuxSection = ({
  className,
  title,
  subtitle,
  primaryCta,
  secondaryCta,
  leftBtn,
  rightBtn,
}: BuySuitOrTuxSectionProps) => {
  return (
    <div className={`m-auto mb-16 max-w-[2000px] pb-32 md:mb-16 md:pb-0 ${className ?? ''}`}>
      <div className="grid grid-flow-row md:grid-flow-col">
        <div className="pl-16 md:pl-32 md:pt-32">
          <div className="relative row-span-2">
            <h3 className="text-h3 mt-24 text-gold-dark">{title.toUpperCase()}</h3>
            <div className="absolute -top-0 -left-16 z-10 bg-gold-dark pl-16 pr-32 text-white md:-left-32 md:pl-32 md:pr-64">
              {subtitle}
            </div>
          </div>
          <div className="mt-64">
            {primaryCta}

            <picture>
              <source
                key="md-up"
                media={`(min-width: ${tw.screens.md})`}
                srcSet={`https://gentux.imgix.net/2x_jackets.png?auto=format`}
              />
              <source key="base" srcSet={`https://gentux.imgix.net/jackets.png?auto=format`} />
              <img className="w-full" alt="A stack of suit jackets" />
            </picture>
          </div>
        </div>

        <div className="flex flex-col justify-center">
          <div className="grid grid-cols-2 gap-y-16 px-0 py-16 xs:gap-x-16 xs:px-32 md:items-start md:justify-between md:py-0 md:px-16">
            <div className="col-span-1 flex flex-row items-center justify-center xs:justify-end md:justify-start">
              <PanelWithFloatBtn
                imgUrl="https://gentux.imgix.net/1732548199_homeredesignshopmenssuit.png?auto=format"
                imgAlt="Man wearing a suit"
                btnText={leftBtn.btnText.toUpperCase()}
                href={leftBtn.href}
                trackerClass={leftBtn.trackerClass}
              />
            </div>

            <div className="col-span-1 flex flex-row items-center justify-center xs:justify-start md:justify-center">
              <PanelWithFloatBtn
                imgUrl="https://gentux.imgix.net/Retail2024_Ariana_W-WhiteNotchSuit_4354.jpeg?auto=format&fit=crop&w=311&max-h=405"
                imgAlt="Woman wearing a suit"
                btnText={rightBtn.btnText.toUpperCase()}
                href={rightBtn.href}
                trackerClass={rightBtn.trackerClass}
              />
            </div>
          </div>

          <div className="mt-16 flex w-full flex-col items-center justify-center text-gray-dark sm:mt-32">
            {secondaryCta}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySuitOrTuxSection;
