import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type DialogActionsProps = PropsWithChildren<{} & ClassNameProp>;

const DialogActions = ({ children, className }: DialogActionsProps) => (
  <div
    className={twMerge(
      'flex flex-none grow-0 flex-row items-center justify-center space-x-16 border-t-1 border-gray bg-gray-lighter p-24',
      className
    )}
  >
    {children}
  </div>
);

export default DialogActions;
