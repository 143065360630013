import React from 'react';

interface Layout1x1Props {
  contentClassName?: string;
  hero?: boolean;
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & Layout1x1Props>;

const Layout1x1: Props = ({ hero, className, children, contentClassName, ...props }) => {
  return (
    <div {...props} className={`container ${className ? className : ''}`}>
      <div className="row">
        {hero ? (
          <div
            className={`
              col-start-1 col-end-13
              space-y-16 lg:col-start-2
              lg:col-end-12
              ${contentClassName}
            `}
          >
            {children}
          </div>
        ) : (
          <div
            className={`
              col-start-1 col-end-13
              space-y-16 xs:col-span-8
              xs:col-start-3 sm:col-end-12
              lg:col-start-4 lg:col-end-10
              ${contentClassName}
            `}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout1x1;
