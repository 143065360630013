import { useEffect } from 'react';

export default function useOnEscapePress(cb: () => void) {
  useEffect(() => {
    const handleUserKeyPress = (ev: globalThis.KeyboardEvent) => {
      if (['Escape', 'Esc'].includes(ev.key)) {
        ev.preventDefault();
        cb();
      }
    };

    document.addEventListener('keyup', handleUserKeyPress);

    return () => {
      document.removeEventListener('keyup', handleUserKeyPress);
    };
  }, [cb]);
}
