import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type DialogTitleProps = PropsWithChildren<ClassNameProp>;

const DialogTitle = ({ className, children }: DialogTitleProps) => (
  <h2 className={twMerge('text-h1-display mt-32 px-24 text-left text-black sm:mt-24', className)}>{children}</h2>
);

export default DialogTitle;
