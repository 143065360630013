import { twMerge } from 'tailwind-merge';

import Line from 'components/Line';

export type SectionTitleProps = {
  color?: 'black' | 'gold-darker';
  line?: boolean;
  primary: string;
  secondary?: string;
  tertiary?: string;
} & ClassNameProp;

const SectionTitle = ({ className, color = 'black', line, primary, secondary, tertiary }: SectionTitleProps) => (
  <div className={twMerge('my-32 text-center', className)}>
    {secondary && <h4 className="text-h5 text-gray-dark md:text-h4 md:text-gray-dark">{secondary}</h4>}

    <h2 className={`text-h2-display md:text-h1-display !text-${color}`}>{primary}</h2>

    {line && <Line className="mx-auto !my-16 w-64 md:!my-24 md:w-[160px]" />}

    {tertiary && <p>{tertiary}</p>}
  </div>
);

export default SectionTitle;
