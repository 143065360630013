import { GetStaticPropsContext } from 'next';
import { withPageStaticProps } from 'utils/header';
import SanityPage from 'components/sanity/SanityPage';
import { getDocumentToBuild, SanityPageProps, withSanityPageProps } from 'components/sanity/withSanityPageProps';
import { getPageSettings, pageByIdQuery } from '@root/server/services/Pages';
import Head from 'next/head';
import RedesignedHomepage from 'components/homepage';

const getIndexProps = withSanityPageProps(async ({ preview = false }: GetStaticPropsContext) => {
  const pageSettings = await getPageSettings();

  let { published, draft } = pageSettings.homepage;

  if (!published) {
    throw new Error('Query for homepage did not return all the expected documents');
  }

  // If we don't currently have a draft, we'll use the published version
  // in case someone views the page in preview mode
  draft ??= published;

  const page = getDocumentToBuild([published, draft], preview);

  return {
    props: {
      data: {
        page,
        query: pageByIdQuery,
        queryParams: {
          id: preview ? draft._id : published._id,
        },
      },
    },
  };
});

export const getStaticProps = withPageStaticProps(getIndexProps);

const Index = (props: SanityPageProps) => (
  <>
    <Head>
      <meta property="og:url" key="og:url" content={`${process.env.NEXT_PUBLIC_LOCAL_URL}`} />

      <meta name="twitter:url" key="twitter:url" content={`${process.env.NEXT_PUBLIC_LOCAL_URL}`} />
      <meta name="twitter:site" key="twitter:site" content="@generationtux" />
      <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
    </Head>

    <SanityPage data={props.data} preview={props.preview}>
      <RedesignedHomepage />
    </SanityPage>
  </>
);

export default Index;
