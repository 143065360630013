import { useState } from 'react';
import type { MouseEvent } from 'react';

export type Tab = {
  title: string;
  content: JSX.Element | string;
};

export type TabsProps = {
  data: Tab[];
  className?: string;
};

const HomePageTabs = ({ data, className }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (tabIndex: number) => (ev: MouseEvent) => {
    ev.preventDefault();

    setActiveTab(tabIndex);
  };

  return (
    <div className={`${className ?? ''}`} role="tablist">
      <div className="mb-16 flex flex-row overflow-x-auto">
        {data.map(({ title }, i) => {
          const isActive = activeTab === i;

          return (
            <button
              className={`text-md mb-0 border-b-1 pb-8 pr-32 font-semibold hover:cursor-pointer md:block ${
                isActive ? 'border-black text-black' : 'border-gray-dark text-gray-dark'
              }`}
              key={`tab-${i}`}
              onClick={handleTabChange(i)}
              role="tab"
              aria-selected={isActive ? 'true' : 'false'}
            >
              {title.toUpperCase()}
            </button>
          );
        })}
      </div>

      {data.map(({ content }, i) => {
        const isActive = activeTab === i;

        return (
          <div aria-expanded={isActive ? 'true' : 'false'} key={`tab-panel-${i}`} role="tabpanel">
            <div className={isActive ? '' : 'hidden'}>{content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HomePageTabs;
