import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<ClassNameProp & DataTestIdProp>;

const DoubleLineHeader = (props: Props) => {
  const beforeCls =
    'before:hidden before:md:block before:border-solid before:border-b-1 before:border-gray before:ml-auto before:w-106';
  const afterCls =
    'after:hidden after:md:block after:border-solid after:border-b-1 after:border-gray after:mr-auto after:w-106';

  return (
    <div data-testid={props['data-testid']} className={props.className}>
      <div className={`${beforeCls} ${afterCls} flex-nowrap items-center sm:text-center md:flex`}>
        <h3 className={`text-h4 mb-4 block flex-initial text-black md:text-h3 md:px-16`}>{props.children}</h3>
      </div>
    </div>
  );
};

export default DoubleLineHeader;
